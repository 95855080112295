<template>
  <div class="livePlayback">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title" @click="goBack"><i class="el-icon-arrow-left"></i>返回</span>
      </div>
      <div class="content">
        <el-row
          v-if="
            (playbackDetail.flvUrl && playbackDetail.liveStatus == 2) ||
              (playbackDetail.vid && playbackDetail.liveStatus == 3)
          "
          type="flex"
        >
          <el-col :span="18">
            <div class="contentHeader">
              <div class="title">
                {{ playbackDetail.liveName }}
                <span class="status" :class="{ active: playbackDetail.liveStatus == 2 }">{{
                  playbackDetail.liveStatus | filterStatus
                }}</span>
              </div>
              <div class="teacher">讲师：{{ playbackDetail.teacherName }}</div>
            </div>
            <div id="prism-player" class="prism-player"></div>
          </el-col>
          <el-col :span="6">
            <el-tabs v-model="activeName">
              <el-tab-pane label="互动" name="first">
                <div class="interaction">
                  <el-collapse-transition>
                    <div v-if="welcomeSatus" class="welcome">欢迎老师进入直播间</div>
                  </el-collapse-transition>
                  <div ref="scroll" class="scroll">
                    <div
                      v-for="item in getClassLiveMsg"
                      :id="item.id"
                      :key="item.id"
                      class="scrollItem"
                    >
                      <div
                        v-if="item.type == 1"
                        class="liveName"
                        :class="{ teacherColor: playbackDetail.isTeacher }"
                      >
                        {{ userName }} :
                      </div>
                      <div v-else class="liveName">{{ item.from }}:</div>
                      <div class="liveMsg">{{ item.sourceMsg }}</div>
                    </div>
                  </div>
                  <div class="footerMsg">
                    <div class="footerInfo">
                      <el-input
                        v-model="message"
                        placeholder="说点什么吧"
                        class="msgInput"
                        @keydown.native.enter="onSendTextMsg(message)"
                      />
                      <el-button type="primary" class="msgBtn" @click="onSendTextMsg(message)"
                        >发送</el-button
                      >
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="'观众（' + num + '）'" name="second">
                <div v-for="(item, index) in audience" :key="index" class="userMain">
                  <img :src="item.headPic" alt="" class="userPic" /><span class="userName">{{
                    item.userName
                  }}</span>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import * as type from '@/store/action-types'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapMutations } = createNamespacedHelpers('user')
import { editSearch, liveAudience, vidSearch, imAddRoom, liveStudyRecord } from '@/api/live'
import to from 'await-to'
import { getCookie } from '@/utils/cookies'
import 'element-ui/lib/theme-chalk/base.css'
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import Vue from 'vue'

Vue.component(CollapseTransition.name, CollapseTransition)
export default {
  filters: {
    filterStatus(val) {
      if (val == 0) {
        return '编辑中'
      } else if (val == 1) {
        return '待开始'
      } else if (val == 2) {
        return '直播中'
      } else {
        return '已结束'
      }
    },
  },
  data() {
    return {
      liveId: this.$route.query.liveId,
      playbackDetail: {},
      activeName: 'first',
      audience: [],
      num: 0,
      audienceNum: null,
      message: '',
      liveMsg: [],
      aliStatus: -1,
      roomNum: '',
      danmukuList: [],
      userName: getCookie('userName') ? getCookie('userName') : '',
      welcomeSatus: false,
    }
  },
  computed: {
    ...mapState({
      getClassLiveMsg(state) {
        if (state.classLiveMsg.length !== 0) {
          this.liveMsg = state.classLiveMsg
          this.liveMsg.map(v => {
            let obj = {
              mode: 1,
              text: v.sourceMsg,
              stime: 1000,
              size: 25,
              color: 0xffffff,
            }
            if (
              JSON.stringify(this.danmukuList).indexOf(JSON.stringify(obj)) == -1 &&
              this.aliStatus !== 1
            ) {
              this.danmukuList.push(obj)
            }
          })
          this.$nextTick(() => {
            this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight
          })
        }
        return this.liveMsg
      },
    }),
  },
  created() {
    this.editSearchData()
    this.liveAudienceData()
    this.audienceNum = setInterval(() => {
      this.liveStudyRecordData()
      this.liveAudienceData()
    }, 5000)
  },
  destroyed() {
    clearInterval(this.audienceNum)
    this[type.CLEAR_IM_MSG]([])
  },
  beforeDestroy() {
    console.log(this.roomNum)
    //退出聊天室
    let option = {
      roomId: this.roomNum,
    }
    // eslint-disable-next-line no-undef
    WebIM.conn.quitChatRoom(option).then(res => {
      console.log(res)
    })
  },
  methods: {
    ...mapActions([type.SET_IM_MSG]),
    ...mapMutations([type.CLEAR_IM_MSG]),
    async liveStudyRecordData() {
      const [, err] = await to(liveStudyRecord({ liveId: this.liveId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
    },
    async liveAudienceData() {
      const [res, err] = await to(liveAudience({ liveId: this.liveId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.audience = res.data
      this.num = res.data.length
    },
    async editSearchData() {
      const [res, err] = await to(editSearch({ liveId: this.$route.query.liveId }))
      if (err) return this.$message.warning(err.msg)
      this.playbackDetail = res.data
      this.imLogin()
      setTimeout(() => {
        this.imAddRoomData()
      }, 100)
      if (!res.data.flvUrl && !res.data.vid) return

      if (res.data.liveStatus == 2 && res.data.flvUrl) {
        this.live(res.data.flvUrl)
      } else if (res.data.liveStatus == 3 && res.data.vid) {
        this.vidSearchData(this.playbackDetail)
      }
      if (this.playbackDetail.isTeacher) {
        let obj = {
          mode: 1,
          text: '欢迎老师进入直播间',
          stime: 1000,
          size: 25,
          color: 0xffffff,
        }
        this.danmukuList.push(obj)
        this.welcomeSatus = true
        setTimeout(() => {
          this.welcomeSatus = false
        }, 3000)
      }
    },
    live(source) {
      let _this = this
      // eslint-disable-next-line no-undef
      this.player = new Aliplayer(
        {
          id: 'prism-player',
          source: source,
          width: '100%',
          height: '500px',
          cover: this.cover,
          autoplay: false,
          isLive: false,
          rePlay: false,
          playsinline: true,
          preload: true,
          controlBarVisibility: 'hover',
          useH5Prism: true,
          components: [
            {
              name: 'AliplayerDanmuComponent', // 弹幕组件
              // eslint-disable-next-line no-undef
              type: AliPlayerComponent.AliplayerDanmuComponent,
              args: [this.danmukuList],
            },
          ],
        },
        function() {},
      )
      var btn = document.querySelector('.danmu-input-enter')
      var danmuInputEle = document.querySelector('.danmu-input input')
      var playValue = ''
      danmuInputEle.onchange = function() {
        playValue = document.querySelector('.danmu-input input').value
      }
      danmuInputEle.onkeydown = function(e) {
        if (e && e.which == 13 && document.querySelector('.danmu-input input').value !== '') {
          _this.onSendTextMsg(document.querySelector('.danmu-input input').value)
          document.querySelector('.danmu-input input').value = ''
          _this.aliStatus = 0
        }
      }
      btn.addEventListener('click', function() {
        if (playValue !== '') {
          _this.onSendTextMsg(playValue)
          playValue = ''
          _this.aliStatus = 1
        }
      })
    },
    async vidSearchData(playbackDetail) {
      const [res, err] = await to(
        vidSearch({
          videoId: playbackDetail.vid,
          videoPlatform: playbackDetail.livePlatform,
        }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.live(res.data.videoUrl)
    },
    //im登录
    imLogin() {
      var options = {
        user: getCookie('imUserName'),
        pwd: getCookie('imSecret'),
        // eslint-disable-next-line no-undef
        appKey: WebIM.config.appkey,
        apiUrl: 'https://a1.easecdn.com',
        success: function(res) {
          console.log(res)
        },
        error: function() {},
      }
      // eslint-disable-next-line no-undef
      WebIM.conn.open(options)
    },
    //加入聊天室
    async imAddRoomData() {
      const [res, err] = await to(
        imAddRoom({ liveId: this.$route.query.liveId, orgId: this.$route.query.orgId }),
      )
      if (err) return this.$message.warning(err.msg)
      this.roomNum = res.data
    },
    //发送消息
    onSendTextMsg(message) {
      if (message.trim()) {
        this.aliStatus = 0
        // eslint-disable-next-line no-undef
        let id = WebIM.conn.getUniqueId() // 生成本地消息id
        // eslint-disable-next-line no-undef
        let msg = new WebIM.message('txt', id) // 创建文本消息
        let option = {
          msg: message, // 消息内容
          to: this.roomNum, // 接收消息对象(聊天室id)
          chatType: 'chatRoom', // 类型设置为聊天室
          ext: {}, // 扩展消息
          success: (id, e) => {
            console.log('发送消息成功', e)
            this[type.SET_IM_MSG]({ message: message, type: 1, id: e })
            this.$nextTick(() => {
              this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight
            })
            this.message = ''
            document.querySelector('.danmu-input input').value = ''
          },
          fail: e => {
            console.error('发送消息异常', e)
            this.message = ''
          },
        }
        msg.set(option)
        // eslint-disable-next-line no-undef
        WebIM.conn.send(msg.body)
      } else {
        this.$message({
          message: '请输入消息',
          type: 'warning',
        })
      }
    },
    goBack() {
      this.$router.push(`/live/${this.$route.query.path}`)
    },
  },
}
</script>

<style scoped lang="scss">
.livePlayback {
  ::v-deep .el-tabs {
    background: #232323;
    .el-tabs__nav {
      height: 70px;
    }
    .el-tabs__content {
      height: 486px;
    }
  }
  ::v-deep .el-tabs__item {
    height: 70px;
    line-height: 70px;
    color: #fff;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #ff7b33;
  }
}

.active {
  border: 1px solid #31f869 !important;
  color: #31f869 !important;
}
.title {
  cursor: pointer;
}
.contentHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 70px;
  background: #232323;
  color: #fff;
  padding-left: 27px;
  box-sizing: border-box;
  .title {
    font-size: 20px;
    color: #fff;
    .status {
      width: 62px;
      height: 24px;
      color: #777777;
      border: 1px solid #777777;
      border-radius: 13px;
      margin-left: 40px;
      font-size: 14px;
      display: inline-block;
      text-align: center;
      line-height: 24px;
    }
  }
  .teacher {
    font-size: 14px;
    color: #909399;
  }
}
.userMain {
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  display: flex;
  .userPic {
    margin: auto 0;
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }
  .userName {
    margin-left: 10px;
    color: #fff;
    font-size: 14px;
  }
}
/* 互动 */
.interaction {
  height: 486px;
  position: relative;
  .welcome {
    line-height: 30px;
    position: relative;
    left: 0px;
    top: 0;
    color: #fff;
    background-color: #ff7b33;
    width: 100%;
    padding-left: 15px;
  }
  .scroll {
    height: 381px;
    overflow-y: auto;
    .scrollItem {
      font-size: 14px;
      color: #fff;
      padding: 10px 5px;
      display: flex;
      flex-wrap: nowrap;
      .liveName {
        color: #ff7b33;
        max-width: 20%;
      }
      .liveMsg {
        color: #fff;
        margin-left: 5px;
        max-width: 80%;
      }
    }
  }
  .footerMsg {
    border-top: 1px solid #333333;
    padding: 10px;
    .footerInfo {
      background: #262626;
      overflow: hidden;
    }
    .msgInput {
      height: 50px;
      ::v-deep input {
        background: #262626;
        border: none;
        resize: none;
        height: 50px;
        line-height: 50px;
      }
    }
    .msgBtn {
      width: 68px;
      height: 24px;
      line-height: 24px;
      float: right;
      margin-right: 8px;
      margin-bottom: 10px;
    }
  }
  .teacherColor {
    color: #409eff !important;
  }
}
</style>
